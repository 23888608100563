import { CodeInputTypes, PatternType } from "./types";

export const PREPARES: Record<CodeInputTypes, (value: string) => string> = {
  number: (value: string) => value.replace(/^\D+$/g, ""),
  text: (value: string) => value,
  password: (value: string) => value,
};

export const PATTERN_PRESET: Record<PatternType, string> = {
  p2: "\\d+(\\.|,)?\\d{0,2}",
};
